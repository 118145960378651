import React from "react";
import { StaticQuery, graphql } from 'gatsby';

import NavCategory from "src/components/nav-category";

/**
 *  Global navigation
 */
class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleNav = this.handleToggleNav.bind(this);
    this.handleToggleSection = this.handleToggleSection.bind(this);
  }

  state = {
    mobileNavClosed: true,
    sectionIsActive: [false, false, false, false, false]
  };

  static getDerivedStateFromProps(props, state) {
    if (props.closeCategory) {
      return state.sectionIsActive = [false, false, false, false, false];
    } else {
      return null;
    }
  }

  // Toggle mobile nav open and closed
  handleToggleNav() {
    this.setState({
      mobileNavClosed: !this.state.mobileNavClosed,
    });
  }

  // Toggle category section when category link clicked
  handleToggleSection(linkIndex) {
    let isActive = Array(this.state.sectionIsActive.length).fill(false);

    // Below conditional was modified to remove ability to close nav by hovering same item that opened nav in the first place. Original code => if (this.state.sectionIsActive[linkIndex] !== true). Additionally modified to provide different behavior based on nav size
    if (window.innerWidth > 1279) {
      if (isActive[linkIndex] !== true) {
        isActive[linkIndex] = true;
        // this.props.updateHeaderState();
      }
    } else {
      if (this.state.sectionIsActive[linkIndex] !== true) {
        isActive[linkIndex] = true;
        // this.props.updateHeaderState();
      }
    }

    this.setState({ sectionIsActive: isActive });
    this.props.updateHeaderState(isActive);
  }

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className={
            this.state.mobileNavClosed
              ? `nav-btn-toggle`
              : `nav-btn-toggle nav-btn-toggle--open`
          }
          onClick={this.handleToggleNav}
          aria-controls="nav-global"
          aria-expanded={this.state.mobileNavClosed ? 'false' : 'true'}
          aria-label="site navigation"
        >
          <svg className="icon icon--nav-open">
            <use xlinkHref="#icon-nav-open" />
          </svg>
          <svg className="icon icon--nav-close">
            <use xlinkHref="#icon-close" />
          </svg>
        </button>

        {/* Note: set nav__cat--active on li to highlight category by default */}
        <nav
          id="nav-global"
          className={
            this.state.mobileNavClosed
              ? `nav-wrapper`
              : `nav-wrapper nav-wrapper--open`
          }
          aria-label="site"
        >

        {/* Get data for the nav categories */}
        <StaticQuery
            query={graphql`
              query {
                wordpressWpMenus {  
                  business {
                    wordpress_fields {
                      menu_order
                      object_id
                      url
                      title
                    }
                   
                  }

                  environment {
                    wordpress_fields {
                      menu_order
                      object_id
                      url
                      title
                    }
                    
                  }

                  community {
                    wordpress_fields {
                      menu_order
                      object_id
                      url
                      title
                    }
                   
                  }  
                  
                  commission {
                    wordpress_fields {
                      menu_order
                      object_id
                      url
                      title
                    }
                  
                  }
                  
                  port_info {
                    wordpress_fields {
                      menu_order
                      object_id
                      url
                      title
                    }
                   
                  }
                }
              }
            `}
            render={data => (
              <ul className="nav">
                <NavCategory category="Business" categoryData={data.wordpressWpMenus.business} slug="business" navSection="0" toggleSection={this.handleToggleSection} active={this.state.sectionIsActive} closeSpecificCategory={this.props.closeCategory} />
                <NavCategory category="Environment" categoryData={data.wordpressWpMenus.environment} slug="environment" navSection="1" toggleSection={this.handleToggleSection} active={this.state.sectionIsActive} closeSpecificCategory={this.props.closeCategory} />
                <NavCategory category="Community" categoryData={data.wordpressWpMenus.community} slug="community" navSection="2" toggleSection={this.handleToggleSection} active={this.state.sectionIsActive} closeSpecificCategory={this.props.closeCategory} />
                <NavCategory category="Commission" categoryData={data.wordpressWpMenus.commission} slug="commission" navSection="3" toggleSection={this.handleToggleSection} active={this.state.sectionIsActive} closeSpecificCategory={this.props.closeCategory} />
                <NavCategory category="Port Info" categoryData={data.wordpressWpMenus.port_info} slug="port-info" navSection="4" toggleSection={this.handleToggleSection} active={this.state.sectionIsActive} closeSpecificCategory={this.props.closeCategory} />
              </ul>
            )}
          />
        </nav>
      </React.Fragment>
    );
  }
}

export default Nav;