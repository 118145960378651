import React from "react";
import Icons from "src/components/icons";
import btnEmailSubmit from "src/images/btn-chevron.png";


class FooterArrow extends React.Component {
  constructor(props) {
    super(props)
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  state = {
    classes: ""
  }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     window.onscroll = () => {
  //       let scroll = window.pageYOffset | document.body.scrollTop;
  //       let topOfFooter = document.getElementById('footer-section').getBoundingClientRect().top;

  //       if (scroll > topOfFooter) {
  //         this.setState({ classes: "arrow-button-background" });
  //       } else {
  //         this.setState({ classes: "" });
  //       }
  //     }
  //   }
  // }

  scrollToTop() {
    // document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  render() {
    return (
      <React.Fragment>
        <Icons />
        <div className="return__to__top--div">
          <button type="button" className={`return__to__top ${this.state.classes}`} onClick={this.scrollToTop}><img alt="" src={btnEmailSubmit} className="return__to__top--svg" /></button>
          <p className="return__to__top--text intro">Back To<br />Top</p>
        </div>
      </React.Fragment>
    )
  }
}

export default FooterArrow;


//<svg className="icon icon--arrow-lt return__to__top--svg"><use xlinkHref="#icon-arrow-lt" /></svg>