import React from "react";
import PropTypes from "prop-types";
// import { Link } from "gatsby";
import Img from "gatsby-image";

import ModalVideo from "src/components/modal-video";
import gradient from "src/images/bg-hero-gradient.png";
import CrisisHomepage from "src/components/crisis-homepage";

/**
 *  Hero (full size or short) at top of main pages and most subpages
 */
class Hero extends React.Component {
  render() {
    const props = this.props;

    const headingTxt = `${props.headingLine1}${props.headingLine2 ? `<br />${props.headingLine2}` : ''}`;
    let imgHeight;
    let maxHeight;
    let minHeight;

    // Type of hero
    switch (props.type) {
      case 'full':
        imgHeight = '100vh';
        maxHeight = '1080px';
        minHeight = '400px';
        break;
      case 'short':
        imgHeight = '60vh';
        maxHeight = '443px';
        minHeight = '320px';
        break;
      default:
        break;
    }

    // Optional category name above h1 and associated ARIA attribute
    const isMainPageWithCategory = props.category && (props.category.toLowerCase() !== 'homepage') ? true : false;
    const headingProps = {};

    if (isMainPageWithCategory) {
      headingProps['aria-describedby'] = "hero-category";
    }

    // console.log(props);

    return (
      <React.Fragment>
        {!this.props.crisis ?
          <React.Fragment>
            <div className={`hero hero--${props.type} ${props.slug === 'business' ? 'hero--inverse' : ''} gutter ${props.slug === 'homepage' && 'homepage--hero'}`} style={{ backgroundImage: `${gradient}` }}>

              <div className="hero__inner">
                {isMainPageWithCategory && (
                  <p className="hero__category slant slant--after" id="hero-category">{props.category}</p>
                )}
                <h1 id="main-heading" className="hero__heading line" {...headingProps} dangerouslySetInnerHTML={{ __html: headingTxt }} />
                {props.subheading && <p className="hero__subheading">{props.subheading}</p>}

                {/* Button (such as Read More). Some links are external, hence the <a> instead of <Link>. */}
                {(props.button && props.buttonLabel) &&
                  <a href={props.button} className="hero__btn-more btn-slanted" aria-describedby="hero-heading">{props.buttonLabel}</a>
                }

                {/* TODO: properly conditionally add ModalVideo if data from WP provides a video ID. */}
                {props.hasVideo && <ModalVideo videoId="i2ZMEI82a7c" />}
              </div>
            </div>

            { /* Display background video over background image */
              props.backgroundVideoId &&
              <div className="hero__video">
                <iframe src={`https://player.vimeo.com/video/${props.backgroundVideoId}?background=1`} width="640" height="360" title="Video background" frameBorder="0" allow="autoplay" tabIndex="-1"></iframe>
              </div>
            }


            {/*TENTATIVE CODE ADJUSTMENT TO TEST WITHOUT USING FLUID*/}
            {
              props.heroBackgroundUrl && props.heroBackgroundUrl.localFile ?
                <React.Fragment>
                  {(props.mobileHeroBackgroundUrl && props.mobileHeroBackgroundUrl.localFile) &&
                    <Img
                      className={`${props.type === "full" ? 'full-gatsby-image' : ''} mobile--hero--image`}
                      fluid={props.mobileHeroBackgroundUrl.localFile.childImageSharp.fluid}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: imgHeight,
                        maxHeight: maxHeight,
                        minHeight: minHeight
                      }}
                    />
                  }

                  {(props.heroBackgroundUrl && props.heroBackgroundUrl.localFile) &&
                    <Img
                      className={`${props.type === "full" ? 'full-gatsby-image' : ''} desktop--hero--image`}
                      fluid={props.heroBackgroundUrl.localFile.childImageSharp.fluid}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: imgHeight,
                        maxHeight: maxHeight,
                        minHeight: minHeight
                      }}
                    />
                  }
                </React.Fragment>

                :

                <React.Fragment>
                  {props.mobileHeroBackgroundUrl &&
                    <img src={props.mobileHeroBackgroundUrl}
                      className={`${props.type === "full" ? 'full-gatsby-image' : ''} mobile--hero--image`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        // height: imgHeight,
                        maxHeight: maxHeight,
                        minHeight: minHeight,
                        objectFit: 'cover'
                      }}
                    />
                  }

                  {props.heroBackgroundUrl &&
                    <img src={props.heroBackgroundUrl}
                      className={`${props.type === "full" ? 'full-gatsby-image' : ''} desktop--hero--image`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: imgHeight,
                        maxHeight: maxHeight,
                        minHeight: minHeight,
                        objectFit: 'cover' // TENTATIVE. MAY NOT KEEP THIS
                      }}
                    />
                  }
                </React.Fragment>
            }
          </React.Fragment>
          :
          <CrisisHomepage />
    
        }
      </React.Fragment>
    );
  }
}

Hero.propTypes = {
  headingLine1: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
  hasVideo: PropTypes.bool,
  headingLine2: PropTypes.string,
  heroBackgroundUrl: PropTypes.object,
  slug: PropTypes.string,
  subheading: PropTypes.string
}

Hero.defaultProps = {
  category: '',
  hasVideo: false,
  headingLine2: '',
  heroBackgroundUrl: null,
  slug: '',
  subheading: ''
}

export default Hero;


/*
 *  Original
 */
// imgHeight = '82vh';
// maxHeight = '100%';


/*
 *  Modified
 */
// imgHeight = '60vh';
// maxHeight = '443px';


// {/*TENTATIVE CODE ADJUSTMENT TO TEST WITHOUT USING FLUID*/ }
// {
//   props.heroBackgroundUrl.localfile ?
//     <React.Fragment>
//       {(props.mobileHeroBackgroundUrl && props.mobileHeroBackgroundUrl.localFile) &&
//         <Img
//           className={`${props.type === "full" ? 'full-gatsby-image' : ''} mobile--hero--image`}
//           fluid={props.mobileHeroBackgroundUrl.localFile.childImageSharp.fluid}
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: imgHeight,
//             maxHeight: maxHeight,
//             minHeight: minHeight
//           }}
//         />
//       }

//       {(props.heroBackgroundUrl && props.heroBackgroundUrl.localFile) &&
//         <Img
//           className={`${props.type === "full" ? 'full-gatsby-image' : ''} desktop--hero--image`}
//           fluid={props.heroBackgroundUrl.localFile.childImageSharp.fluid}
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: imgHeight,
//             maxHeight: maxHeight,
//             minHeight: minHeight
//           }}
//         />
//       }
//     </React.Fragment>

//     :

//     <React.Fragment>
//       {props.mobileHeroBackgroundUrl &&
//         <img src={props.mobileHeroBackgroundUrl}
//           className={`${props.type === "full" ? 'full-gatsby-image' : ''} mobile--hero--image`}
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: imgHeight,
//             maxHeight: maxHeight,
//             minHeight: minHeight
//           }}
//         />
//       }

//       {props.heroBackgroundUrl &&
//         <img src={props.heroBackgroundUrl}
//           className={`${props.type === "full" ? 'full-gatsby-image' : ''} desktop--hero--image`}
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: imgHeight,
//             maxHeight: maxHeight,
//             minHeight: minHeight
//           }}
//         />
//       }
//     </React.Fragment>
// }