import React from "react";
import moment from "moment";

// import { timesSeries } from "async";


class GlobalAlert extends React.Component {
  constructor(props) {
    super(props)
    this.generateDate = this.generateDate.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.createClassNames = this.createClassNames.bind(this);
  }

  state = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    date: "",
    sessionClose: false,
    closable: false,
    alertTitle: "",
    alertColor: "",
    alertContent: "",
    alertStartTime: null,
    alertEndTime: null,
    alertClasses: ""
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window.sessionStorage.getItem("closedAlert") === null) {
        window.sessionStorage.setItem("closedAlert", false);
      } else if (window.sessionStorage.getItem("closedAlert") === "true") {
        this.setState({ sessionClose: true });
      }

      fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/alert`)
        .then(response => response.json())
        .then(json => {
          // console.log(json);
          if (json.length > 0) {
            this.setState({
              closable: json[0].acf.dismissable,
              alertTitle: json[0].title.rendered,
              alertContent: json[0].acf.content.replace(/<p>/, '').replace(/<\/p>/, ''),
              alertColor: json[0].acf.alert_background_color,
              alertEndTime: json[0].acf.display_end_time
            }, () => {
              this.generateDate();
              this.createClassNames();
            });
          }
        })
    }
  }

  generateDate() {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    let date;
    let dateComparison;

    if (isIE) {
      date = moment().utc();
      dateComparison = moment(this.state.alertEndTime).utc();
      dateComparison.add(3, 'h');
      // dateComparison.utc().format('YYYY-MM-DD HH:mm:ss');

      // console.log(dateComparison);
      // console.log(date);

      if (moment(date).isAfter(moment(dateComparison))) {
        // console.log("shouldn't be closed in date function either");
        this.setState({ sessionClose: true }, () => {
          this.createClassNames()
        });
      } else {
        this.setState({ date: date });
      }
    } else {
      date = moment().utc().format('YYYY-MM-DD HH:mm:ss');
      dateComparison = moment(this.state.alertEndTime).utc().format('YYYY-MM-DD HH:mm:ss');

      // console.log(moment(date).isAfter(moment(dateComparison)));
      // console.log(moment(date).utc());
      // console.log(moment(dateComparison).utc());

      if (moment(date).isAfter(moment(dateComparison))) {
        // console.log("shouldn't be closed in date function either");
        this.setState({ sessionClose: true }, () => {
          this.createClassNames()
        });
      } else {
        this.setState({ date: date });
      }
    }
  }

  createClassNames = () => {
    let classes = "global__alert";

    if (!this.state.sessionClose) {
      // console.log("class function should be showing alert");
      classes += " show-alert";
    }

    this.setState({ alertClasses: classes });
  }

  closeAlert() {
    document.getElementsByTagName("main")[0].removeAttribute("style");
    window.sessionStorage.setItem("closedAlert", true);
    this.setState({ sessionClose: true }, () => {
      this.createClassNames()
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="global-alert" className={this.state.alertClasses} style={{ backgroundColor: this.state.alertColor }}>
          <div className="global__alert--inner">
            {this.state.closable ?
              <button type="button" className="global__alert--close" onClick={this.closeAlert}
                aria-label="close search form">
                <svg className="icon--close--search">
                  <use xlinkHref="#icon-close" />
                </svg>
              </button>
              :
              null
            }
            <h2 className="global__alert--headline" dangerouslySetInnerHTML={{ __html: this.state.alertTitle }} />
            <p className="intro global__alert--content" dangerouslySetInnerHTML={{ __html: this.state.alertContent }} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default GlobalAlert;