import React from "react";
import { Link } from "gatsby";

/**
 *  Breadcrumb on subpages
 */
class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.copyUrlToClipboard = this.copyUrlToClipboard.bind(this);
    this.showMobileShare = this.showMobileShare.bind(this);
    // this.displaySubpageInCrumb = this.displaySubpageInCrumb.bind(this);
  }

  state = {
    displayMobileShare: false,
    currentPage: "",
    currentSubpageTitle: ""
  }

  componentDidMount() {
    let url = "";
    if (typeof window !== undefined) {
      url = window.location.href;
    }

    this.setState({ currentPage: url });
  }

  showMobileShare() {
    this.setState({ displayMobileShare: !this.state.displayMobileShare });
  }

  copyUrlToClipboard() {
    const body = document.getElementsByTagName('body')[0];
    const tempInput = document.createElement("INPUT");
    body.appendChild(tempInput);
    tempInput.setAttribute("value", this.state.currentPage);
    tempInput.select();
    document.execCommand("copy");
    body.removeChild(tempInput);
  }



  render() {
    const props = this.props;
    // const testUrl = "https://polb-stage.stellaragency.com/environment/clean-trucks#program-details";
    const hashedUrl = this.state.currentPage.replace("#", "%23");
    // console.log(props);

    // let currentSubpageTitle = this.state.currentSubpageTitle;
    let subMainPage = false;
    let linkTo = "";
    let categories = props.categories;
    if (props.categories === "Community Grants Program") {
      subMainPage = true;
      linkTo = "community/community-grants-program-main";
    } else if (props.categories === "Education") {
      subMainPage = true;
      linkTo = "community/education";
    }

    // any profile page should lead back to the Commission main page
    if (Array.isArray(props.categories)) {

      let result = props.categories.findIndex((current) => {
        return current.slug === "profile"
      });

      if (result >= 0) {
        categories = [{ name: 'Commission', slug: "commission" }];
      }
    }

    let requiredPage = true;
    if (props.pageTitle === "Overview" || props.pageTitle === "News &#038; Press" || props.pageTitle === "Education" || props.pageTitle === "Community Grants Program" || props.categories[0] === 274) {
      requiredPage = false;
    }

    if (props.socialShare) {
      requiredPage = true;
    }

    // WP API Category Key:
    // 274 = profile
    // 12, 24, 42 = business
    // 11, 13, 41 = environment
    // 6, 9, 38, 44 = community
    // 2, 8, 36, 43 = commission
    // 40, 45, 54 = port info
    // 364 = education
    // 332 = grants
    let mainCategory = [];
    if (categories) {
      if (categories[0] === 12 || categories[0] === 24 || categories[0] === 42) {
        mainCategory = [{ name: "Business", slug: "business" }];
      } else if (categories[0] === 11 || categories[0] === 13 || categories[0] === 41) {
        mainCategory = [{ name: "Environment", slug: "environment" }];
      } else if (categories[0] === 6 || categories[0] === 9 || categories[0] === 38 || categories[0] === 44) {
        mainCategory = [{ name: "Community", slug: "community" }];
      } else if (categories[0] === 2 || categories[0] === 8 || categories[0] === 36 || categories[0] === 43 || categories[0] === 274) {
        mainCategory = [{ name: "Commission", slug: "commission" }];
      } else if (categories[0] === 40 || categories[0] === 45 || categories[0] === 54) {
        mainCategory = [{ name: "Port Info", slug: "port-info" }];
      } else {
        mainCategory = [{ name: "Port Info", slug: "port-info" }];
      }
    }

    return (
      <nav className={`breadcrumb--section gutter ${this.state.displayMobileShare ? 'long-margin' : ''}`} aria-label="breadcrumb">
        {subMainPage ?

          <ol className="breadcrumb">
            {
              categories && <li className="slant"><Link to="/community/" dangerouslySetInnerHTML={{ __html: "community" }} /></li>
            }
            {
              categories && <li className={`slant ${props.categories === 'Community Grants Program' && 'long-breadcrumb-word'}`}><Link to={`/${linkTo}/`} dangerouslySetInnerHTML={{ __html: categories }} /></li>
            }
            <li className={`slant ${props.categories === 'Community Grants Program' && 'long-breadcrumb-word'}`} aria-current="page" dangerouslySetInnerHTML={{ __html: props.pageTitle }} />
          </ol>

          :

          <ol className="breadcrumb">
            {
              categories && <li className="slant"><Link to={`/${mainCategory[0].slug}/`} dangerouslySetInnerHTML={{ __html: mainCategory[0].name }} /></li>
            }
            <li className="slant" aria-current="page" dangerouslySetInnerHTML={{ __html: props.pageTitle }} />
          </ol>

        }

        {props.pageId === 946 &&
          <Link to="/port-info/news-archive/" className="main--link breadcrumb--button"><span className="main--link__text">News Archive</span></Link>
        }

        <div className={`horizontal__social__media__panel ${requiredPage ? '' : 'social__media__share--hide'}`}>
          <a href={`https://facebook.com/sharer.php?u=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Facebook page"><svg className="icon icon--social"><use xlinkHref="#icon-facebook" /></svg></a>
          <a href={`https://twitter.com/intent/tweet?url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--social"><use xlinkHref="#icon-twitter" /></svg></a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach LinkedIn page"><svg className="icon icon--social"><use xlinkHref="#icon-linkedin" /></svg></a>
          <button type="button" onClick={this.copyUrlToClipboard}><svg className="icon icon--social"><use xlinkHref="#icon-link" /></svg></button>
        </div>

        <div className={`social__media__share ${requiredPage ? '' : 'social__media__share--hide'}`}>
          <button type="button" onClick={this.showMobileShare} className={`mobile--share--button ${this.state.displayMobileShare ? 'show-close-button' : ''}`}>
            <svg className="icon icon--footer"><use xlinkHref="#icon-share" /></svg>
            <svg className="icon icon--nav-close"><use xlinkHref="#icon-close" /></svg>
          </button>
          <div className={`social__media__share--mobile ${this.state.displayMobileShare ? '' : 'no-mobile-show'}`}>
            <div className="social__media__share--title-bar">Share</div>
            <ul className="social__media__share--mobile--list">
              <li className="social__media__share--mobile--list__entry intro">
                <a href={`https://facebook.com/sharer.php?u=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Facebook page"><svg className="icon icon--social"><use xlinkHref="#icon-facebook" /></svg><span>Facebook</span></a>
              </li>
              <li className="social__media__share--mobile--list__entry intro">
                <a href={`https://twitter.com/intent/tweet?url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--social"><use xlinkHref="#icon-twitter" /></svg><span>Twitter</span></a>
              </li>
              <li className="social__media__share--mobile--list__entry intro">
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--social icon--social--linkedin"><use xlinkHref="#icon-linkedin" /></svg><span>LinkedIn</span></a>
              </li>
              <li className="social__media__share--mobile--list__entry intro">
                <button type="button" onClick={this.copyUrlToClipboard}><svg className="social--share icon--social"><use xlinkHref="#icon-link" /></svg></button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Breadcrumb;
