import React from "react";
import { Link } from "gatsby";

import NavCategorySection from "src/components/nav-category-section";

/**
 *  A category within the global nav (like Business, Environment, etc.)
 */
class NavCategory extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickLink = this.handleClickLink.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  state = {
    windowWidthMinimum: false
  }

  componentDidMount() {
    this.updateDimensions();
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", this.updateDimensions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Call parent function that updates state of which content to show
  handleClickLink(e) {
    e.preventDefault();
    this.props.toggleSection(e.currentTarget.dataset.navsection);
  }

  updateDimensions() {
    if (window.innerWidth > 1279) {
      this.setState({ windowWidthMinimum: true });
    } else {
      this.setState({ windowWidthMinimum: false });
    }
  }

  render() {
    const props = this.props;
    const data = props.categoryData;
    const linksStatus = props.active;
    const categorySectionId = `nav-category-section-${props.category.replace(' ', '').toLowerCase()}`;

    return (
      <React.Fragment>
        <li className={`nav__cat ${!props.closeSpecificCategory && linksStatus[props.navSection] ? 'nav__cat--active' : ''}`}>
          <Link
            to={data.wordpress_fields[0].url}
            role="button"
            className="nav__cat-btn slant"
            data-navsection={props.navSection}
            activeClassName="nav__cat--active"
            partiallyActive={true} 
            onClick={this.state.windowWidthMinimum ? null : this.handleClickLink}
            onMouseEnter={this.state.windowWidthMinimum ? this.handleClickLink : null}
            aria-controls={categorySectionId}
            aria-expanded={linksStatus[props.navSection] ? 'true' : 'false'}
            aria-label={`${props.category} navigation`}
          > 
            {props.category}
          </Link>
          <NavCategorySection slug={props.slug} cat={props.category} catSectionId={categorySectionId} links={data.wordpress_fields} />
        </li>
      </React.Fragment>
    );
  }
}

export default NavCategory;

