const React = require('react');
const AriaModal = require('react-aria-modal');
// See https://github.com/davidtheclark/react-aria-modal for more info and demos

/**
 *  Show video in modal, such as from Watch Video play button Home
 */
class ModalVideo extends React.Component {
  constructor(props) {
    super(props);

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.getApplicationNode = this.getApplicationNode.bind(this);
  }

  state = {
    modalActive: false
  };

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
  };

  getApplicationNode = () => {
    return document.getElementById('application');
  };

  render() {
    const modal = this.state.modalActive
      && <AriaModal
          titleText="YouTube video"
          onExit={this.deactivateModal}
          initialFocus="#video-iframe"
          getApplicationNode={this.getApplicationNode}
          verticallyCenter={true}
        >
          <div className="modal">
            <div className="modal__body">
              <iframe id="video-iframe" width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${this.props.videoId}`} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="YouTube video"></iframe>
            </div>
            <footer className="modal__footer">
              <button type="button" id="demo-one-deactivate" onClick={this.deactivateModal}>
                close modal
              </button>
            </footer>
          </div>
        </AriaModal>

    return (
      <div>
        {/*<button type="button" className="hero__btn-play" onClick={this.activateModal}><svg className="icon icon--play"><use xlinkHref="#icon-play" /></svg> Watch Video</button>*/}
        {modal}
      </div>
    );
  }
}

export default ModalVideo;
