import React from "react";

/**
 *  ARIA live region used to announce to screen reader users when a page has loaded. 
 *  See the following article for research and other accessibility suggestions regarding assisting users when 
 *  a new page loads in a single-page app like a Gatsby site:
 *
 *    https://www.gatsbyjs.org/blog/2019-07-11-user-testing-accessible-client-routing/
 */
class LiveRegion extends React.Component {
  render() {
    const props = this.props;

    return (
      <div id={props.regionId} className="visuallyhidden" role="status">{props.pageTitle} {props.message}</div>
    );
  }
}

export default LiveRegion;
