/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Icons from "./icons";
import Header from "./header";
import Footer from "./footer";
import "../styles/polb.scss";
import GlobalAlert from "./global-alert";

// siteTitle={data.site.siteMetadata.title}

const Layout = ({ hasHero, pageTitle, children }) => {
  return (
    <React.Fragment>
      <Icons />
      <GlobalAlert />
      <div className="main-body">
        <Header pageTitle={pageTitle} omitBackground={hasHero} />
        <main>{children}</main>
        <Footer />
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
