/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  
  //Add the right format for the special characters
  title = title && title.replace(/&#038;/, '&').replace(/&#8211;/, '-').replace(/&#8217;/, "'").replace(/&#8216;/, "'");
  description = description && description.replace(/<p>/, '').replace(/<\/p>/, '');
  const metaDescription = description || site.siteMetadata.description
  // console.log(">>> siteMetaData: " + site.siteMetadata);
  // Object.keys(site.siteMetadata).forEach(key => {
  //   console.log(`${key}: ${site.siteMetadata[key]}`);
  // });

  // console.log("site = " + site);
  // console.log("title = " + title);
  // console.log("description = " + description);
  // console.log("metaDescription = " + metaDescription);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: url
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        // {
        //   property: `og:image`,
        //   content: `https://thehelm.polb.com/wp-content/uploads/n-q-new-website-brand.jpg`
        // },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
