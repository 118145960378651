import React from "react";


class CrisisHomepage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="crisis--page">
          <div className="crisis--page__info gutter">
            <div className="crisis--page__alert">
              <h4 className="crisis--page__subtitle">Crisis News Alert</h4>
              <h2 className="crisis--page__title">Oil Spill Near Long Beach Pier</h2>
              <p className="intro crisis--page__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris cursus turpis ac scelerisque finibus. Cras magna urna, accumsan et purus eget.</p>
            </div>
            <div className="crisis--page__photo"></div>
          </div>
          <div className="crisis--page__media">
            <div className="crisis--page__media__posts gutter">
              <div className="crisis--page__media__tweet"></div>
              <div className="crisis--page__media__tweet"></div>
              <div className="crisis--page__media__tweet"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default CrisisHomepage;