import React from "react";
import { Link } from "gatsby";

import FooterArrow from "src/components/footer-arrow";
import ModalSignup from "src/components/modal-signupForm";
import logo from "src/images/logo-polb-update-final-2.png";

/**
 *  Page footer
 */
class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.updateState = this.updateState.bind(this);
    this.sychronizeEmail = this.sychronizeEmail.bind(this);
  }

  state = {
    emailAddress: '',
    email: ''
  }

  // Event handler for when email address form submitted
  updateState() {
    this.setState({ emailAddress: this.emailInput.current.value });
  }

  sychronizeEmail(email) {
    this.setState({ email: email });
  }

  render() {
    return (
      <footer id="footer-section" className="footer-wrapper" role="contentinfo" aria-label="site footer">
        <div className="footer">
          <div className="footer__logo-container">
            <img src={logo} alt="Port of Long Beach logo" className="footer__logo" />
            <FooterArrow />
          </div>

          <div className="footer__sections">
            {/* Contact Us */}
            <section className="contact">
              <h5 className="contact__head footer__head">Contact Us</h5>
              <h6 className="contact__subhead">Port of Long Beach</h6>
              <p className="contact__info">
                415 W. Ocean Blvd.<br />
                Long Beach, CA 90802
                <br />
                or
                <br />
                P.O. Box 570<br />
                Long Beach, CA 90801
              </p>

              <h6 id="phone-dispatcher" className="contact__subhead">Harbor Patrol Dispatcher: <span className="visuallyhidden">phone number</span></h6>
              <p className="contact__info"><a href="tel:+5622837820" aria-describedby="phone-dispatcher">(562) 283-7820</a> 24 hrs.</p>

              <h6 id="phone-general" className="contact__subhead">General: <span className="visuallyhidden">phone number</span></h6>
              <p className="contact__info"><a href="tel:+5622817000" aria-describedby="phone-general">(562) 283-7000</a></p>

              <Link to="/port-info/contact-us" className="contact__footer-email-link"><h6 className="contact__subhead contact__email contact__text">EMAIL THE PORT</h6></Link>
            </section>

            {/* Explore */}
            <section className="explore">
              <h5 className="footer__head">Explore</h5>
              <ul className="explore__list">
                <li><Link to="/business/">Business</Link></li>
                <li><Link to="/environment/">Environment</Link></li>
                <li><Link to="/community/">Community</Link></li>
                <li><Link to="/commission/">Commission</Link></li>
                <li><Link to="/port-info/">Port Info</Link></li>
                <li><Link to="/">Resources</Link></li>
                <li><Link to="/port-info/map/">Map</Link></li>
                <li><Link to="/port-info/calendar-of-events/">Calendar</Link></li>
                <li><Link to="/port-info/news-and-press/">News</Link></li>
                <li><Link to="/jobs/">Jobs</Link></li>
                <li><Link to="/documents">Documents</Link></li>
                <li><Link to="/business/port-operations-and-facilities">Facilities</Link></li>
              </ul>
            </section>

            {/* Stay Connected */}
            <section className="connect">
              <h5 className="footer__head">Stay Connected</h5>
              <div className="form-email">
                <div id="mc_embed_signup_scroll">
                  <label htmlFor="mce-EMAIL" className="visuallyhidden">Email Address</label>
                  <input type="email" placeholder="Enter your email address" name="EMAIL" className="required email form-email__field" ref={this.emailInput} defaultValue={this.state.email} id="mce-EMAIL" />

                  <div aria-hidden="true" hidden><input type="text" name="b_edc1afb38bb598ae75feba8ce_7afdb8434c" tabIndex="-1" /></div>

                  <ModalSignup email={this.state.emailAddress} updateState={this.updateState} sychronizeEmail={this.sychronizeEmail} />
                </div>
              </div>

              <ul className="connect__social">
                <li>
                  <a href="http://www.facebook.com/portoflongbeach" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Facebook page"><svg className="icon icon--footer"><use xlinkHref="#icon-facebook" /></svg></a>
                </li>
                <li>
                  <a href="https://twitter.com/portoflongbeach" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--footer"><use xlinkHref="#icon-twitter" /></svg></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/PortOfLongBeach/" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Instagram page"><svg className="icon icon--footer"><use xlinkHref="#icon-instagram" /></svg></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/port-of-long-beach/?viewAsMember=true" rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach LinkedIn page"><svg className="icon icon--footer"><use xlinkHref="#icon-linkedin" /></svg></a>
                </li>
              </ul>

              <div className="connect__policy"><a href="https://www.longbeach.gov/citymanager/ada/titlevi/" target="_blank">TITLE VI NON-DISCRIMINATION POLICY</a></div>
            </section>
          </div>

          <p className="footer__legal"><small>&copy; 2024 Port of Long Beach</small></p>
        </div>
      </footer>
    );
  }
}

export default Footer;
